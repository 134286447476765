/*
* 업무구분: 신탁>고객/상품>고객정보조회>해지예상금조회
* 화 면 명: MSPTS109M
* 화면설명: 해지예상금조회
* 작 성 일: 2023.03.28
* 작 성 자: 지재호
* 파 일 명: TFAC23300.xfdl , 2510
*/
<template>
  <ur-page-container class="fts" :show-title="false" title="해지예상금조회" :topButton="true">

    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left">
                <div class="wrap-input row">
                  <label class="emphasis"> 계좌번호 </label>
                  <mo-decimal-field class="w130 input-account" 
                    numeric
                    mask="#######-###"
                    @click="[removeError($event.currentTarget)]"
                    @keyup="Edt_acno_onkeyup"
                    v-model="Edt_acno"
                    :class="{'comp': Edt_acno.length > 9}"
                    clearable
                    ref="Edt_acno"
                  />
                  <mo-dropdown :items="list_Cbo_accSeq" v-model="Cbo_accSeq" class="w260" placeholder=" " />
                  <mo-button class="btn-pop-download" @click="fn_OpenPopup(0)"> </mo-button>
                  <mo-text-field 
                    disabled
                    class="w130"
                    ref="edt_cnm"
                    v-model="edt_cnm"
                  />
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left w100p">

                <div class="wrap-input row flex-unset" >
                  <label> 결산일자 </label>
                  <mo-date-picker v-model="cal_baseDate00" :bottom="false" class="w130" ref="cal_baseDate00" disabled/>
                </div>

              </div>
              <div class="right">
                <div class="wrap-button row">
                  <mo-button @click="fn_init"> 초기화 </mo-button>
                  <mo-button primary @click="fn_searchList"> 조회 </mo-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">                 
        <div class="wrap-table-title row">
          <h2 class="table-title"> 신탁이익계산서 </h2>
          <mo-radio-wrapper :items="list_prrtCls" v-model="prrtCls" class="ml-5"/>
        </div>
        <div class="wrap-table">
          <table class="table col-type col1419">
            <tbody>
              <tr>
                <th> 신탁유형 </th>
                <td class="aL"> {{ds_detail.trstTypCNm}} </td>
                <th> 신탁펀드 </th>
                <td class="aL"> {{ds_detail.gdNm}} </td>
              </tr>
              <tr>
                <th> 계좌번호 </th>
                <td class="aR"> {{$tsUtil.gfn_accnoReplace(getValue(), true)}} </td>
                <th> 고객명 </th>
                <td class="aL"> {{ds_detail.cnm}} </td>
              </tr>
              <tr>
                <th> 수탁원본 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.trustAmt)}} </td>
                <th> 과표금액 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.txblWcurAmt)}} </td>
              </tr>
              <tr>
                <th> 해지원본 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.tmtlWcurAmt)}} </td>
                <th> 소득(법인)세 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.itaxWcurAmt)}}</td>
              </tr>
              <tr>
                <th> 운용수익금 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.totAppnBnfWcurAmt)}} </td>
                <th> 주민세 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.rtaxWcurAmt)}} </td>
              </tr>
              <tr>
                <th> 신탁보수 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.totTrstFeeWcurAmt)}} </td>
                <th> 총세금합 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.totWcurAmt)}} </td>
              </tr>
              <tr>
                <th> 선취보수 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.epayFeeAmt)}} </td>
                <th> 기지급세금 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.jbscTaxWcurAmt)}} </td>
              </tr>
              <tr>
                <th> 중도해지수수료 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.mdtmComsWcurAmt)}} </td>
                <th> 세후지급금액 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.payAmt)}} </td>
              </tr>
              <tr>
                <th> 신탁이익금 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.trstPrftWcurAmt)}} </td>
                <th> 해지후 수탁원본 </th>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_detail.rcstRamtWcurAmt)}} </td>
              </tr>
              <tr>
                <th> {{prrtClsTtTxt}} </th>
                <td class="aR"> {{ds_detail.taxbPrrt}} </td>
                <th>  </th>
                <td>  </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="desc02 mt-2"><em class="colorR">※ 본 내역은 결산일자의 전일 종가로 평가된 내역입니다.  실제 해지금액과 차이가 있을 수 있습니다.</em></p>
		  </ur-box-container>
    </div>
    <!-- //.fts-main -->

  <!-- alert modal popup -->
  <ts-alert-popup 
    ref="alertPopup"
    :popupObj="pAlertPopupObj"
  ></ts-alert-popup>

  <!-- popup206 계좌번호 조회 --> 
  <msp-ts-206p
    ref="popup206"
    @ts-alert-popup="fn_AlertPopup"
    @ts-popup206-callback="fn_Popup_CallBack"      
    :popupObj="pPopup206Obj"
  ></msp-ts-206p>

  </ur-page-container>
</template>

<script>
  /***********************************************************************************
   * DataSet 영역	                                                                    *
   ***********************************************************************************/
  const defaultDsList = {  // 검색바 데이터 셋
    tacno: '',
    bnkbSeq: '',
    accSeq: '',
    accStatC: '',
    gdNm: '',
    cnm: '',
    brigAccYn: '',
    curC: '',
    contDate: '',
    mtrtDate: '',
    trstTypC: '',
    gdC: '',
    trstAmt: '',
    gdTypDtlC: '',
    mngBrcd: '',
    csId: '',
    admkNm: '',
    bnkb: '',
    acc: '',
    fundC: '',
    rcno: '',
    rcnoKindTc: '',
    soptTc: '',
  }

  const defaultDsSearch = {  // 검색바 데이터 셋
    tacno: '',
    bnkbSeq: '',
    accStatC: '',
    brigAccYn: '',
  }

  const defaultDataSetTemp = {  // 검색바 데이터 셋
    tacno: '',
    bnkbSeq: '',
    accSeq: '',
    gdC: '',
    gdNm: '',
    trstTypC: '',
    cnm: '',
    rcno: '',
    rcnoKindTc: '',
    accStatC: '',
    bnkbPswd: '',
  }

  const defaultDsMsData = {  // 검색바 데이터 셋
    msData: '',
    pbprNo: '',
    msUseYn: '',
  }

  // 메인 데이터 셋
  const defaultMainDsSearch = {  // 메인 데이터 셋
    tmtlReqSeq: '',
    baseDate: '',
    tacno: '',
    bnkbSeq: '',
    accSeq: '',
    prrtCls: '',
  }

  const defaultDsDetail = {  // 메인 데이터 셋
    totTrstFeeWcurAmt: '',
    rtaxWcurAmt: '',
    itaxWcurAmt: '',
    txblWcurAmt: '',
    withholdAmt: '',
    totWcurAmt: '',
    payAmt: '',
    rcstRamtWcurAmt: '',
    tmtlWcurAmt: '',
    totAppnBnfWcurAmt: '',
    mdtmComsWcurAmt: '',
    trstPrftWcurAmt: '',
    taxbPrrt: '',
    trstTypCNm: '',
    gdNm: '',
    cnm: '',
    baseDate: '',
    tacno: '',
    bnkbSeq: '',
    accSeq: '',
    trustAmt: '',
    fundC: '',
    baseYear: '',
    baseMm: '',
    baseDd: '',
    jbscTaxWcurAmt: '',
    epayFeeAmt: '',
    accno: '',
  }

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
  import TSHeader from '~/src/ui/ts/comm/TSHeader'          // header 영역 (공통)

  // 팝업
  import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)
  import MSPTS206P from '~/src/ui/ts/MSPTS206P'       // 계좌번호 조회
  import TSInfoUtil    from '~/src/ui/ts/comm/TSInfoUtil'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPTS109M",
    screenId: "MSPTS109M",
    components: {
      'ts-header': TSHeader,
      'ts-alert-popup': TSAlertPopup,
      'msp-ts-206p': MSPTS206P,

    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      
      // data 속성에 할당
      this.TSCommUtil = TSCommUtil 

      // 화면 초기화
      this.fn_afterFormOnload()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        TSCommUtil:{},
        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
        // 공통 객체
        pHeaderObj: {          
          title: '해지예상금조회',
        },

        lv_basInfo: this.getStore('tsStore').getters.getBasInfo.data,

        // Contents Model 
        eaiCommObj: TSCommUtil.gfn_eaiCommObj(),

        // 팝업 객체
        pAlertPopupObj: {}, // 공통 객체
        pPopup206Obj: {},   // 계좌번호 조회

        /*********** 
         * 공통  
        ************/
        enabledList: {
          Cbo_accSeq:'',
        },
        visibleList: {
          Cbo_accSeq:'',
        },

        /*********** 
         * 검색바 
        ************/
        pbprUseYn: '',   
        passYn: '',
        popCls: '',
        Edt_acno: '', // 계좌번호 입력 // 1101605000 : 테스트용
        edt_cnm: '',
        edt_accSeq:'', // 계좌일련번호 첫
        edt_curC: '', // 통화코드
        cal_contDate: '', // 계약일자
        cal_mtrtDate: '', // 만기일자
        edt_brigAccYn: '', // 브릿지계좌여부
        edt_trstTypC: '', // 신탁유형
        edt_gdC: '', // 상품코드
        edt_gdNm: '', // 상품명
        edt_trstAmt: '', // 신탁금액
        edt_gdTypDtlC: '', // 상품유형 코드
        edt_mngBrcd: '',  // 관리지점 코드
        edt_csId: '', // 고객id
        edt_cnm: '',
        edt_fundC: '', // 펀드코드
        edt_rcno: '', // 실명번호
        edt_rcnoKindTc: '', // 실명번호 종류
        edt_soptTc: '', // 자타익 구분
        Edt_accStatC_inData:'',
        edt_bnkbAccYn: '', // 계좌여부
        baseDate00: '', // 결산일자
        ds_list: [],
        ds_search: Object.assign({}, defaultDsSearch),
        DatasetTemp: Object.assign({}, defaultDataSetTemp),
        ds_msData: Object.assign({}, defaultDsMsData),
        list_Cbo_accSeq:[], 
        Cbo_accSeq: '', // 계좌번호 select박스 value

        /************
         * 메인
        ***********/
        ds_detail: Object.assign({}, defaultDsDetail),
        ds_m_search: Object.assign({}, defaultMainDsSearch),

        prrtCls: '2', // 신탁 이익계산서 RADIO버튼, default: 
        prrtClsTtTxt: '연환산수익률', // default

        valueProfit: '2',
        valueDate1: '1982-02-01',
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      list_prrtCls() {
        let rtn = [];
        rtn.push({value: '1', text: '단순수익률'});
        rtn.push({value: '2', text: '연환산수익률'});
        return rtn;
      },

      cal_baseDate00: {
        set: function(param) {
          let repParam = param.replaceAll("-","")
          // if ( repParam != this.ds_detail.btdt ) {
          //   this.ds_detail.btdt = repParam
          // }
          this.baseDate00 = repParam
        },
        get: function() {
          return TSCommUtil.gfn_dateReplace(1, this.baseDate00)
        }
      },

      // modal
      modal1() {return this.$refs.modal1},
      modal2() {return this.$refs.modal2},
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      // modal
      openModal(type) {
        switch (type) {
          case 1:
            this.modal1.open();
            break;

          case 2: 
            this.modal2.open();
            break;
        }
      },
      closeModal(type) {
        switch (type) {
          case 1:
            this.modal1.close();
            break;
          case 2:
            this.modal2.close();
            break; 
        }
      },
      /////////넥사소스///////

      /***************************************************************************************
          실질적인 화면 초기화 
      *****************************************************************************************/
      fn_afterFormOnload ()
      {
        // 대응하는 소스 라인 없음
        //공통코드 콤보셋 호출
        // var param = [
        //   {code:"5032",dsName:"ds_cmb5032"}        //부동산신탁금융감독원코드	
        // ];  
        // this.gfn_setCommonCode(param);
        
        // 검색바 
        this.pbprUseYn = "N";
	      this.passYn = "N";

        this.fn_init();
        
        console.log(this.$route.params)
        if ( this.$route.params.reportParam !== undefined ) {
          const parsObj = JSON.parse( this.$route.params.reportParam )
          console.log(parsObj)
          console.log(this.$route.params)

          // TDOO : 이부분은 좀더 확인해봐야함
          // this.ds_search.rcnoKindTc = parsObj.rcnoKindTc
          this._setValue( _P_PARAMS.tacno+_P_PARAMS.bnkbSeq , _P_PARAMS.accSeq);
          
          // this.Div02.cal_baseDate00._setValue(_P_PARAMS.baseDate); //기준일자셋팅 
        }

        // 대응하는 소스 라인 없음
        // _P_PARAMS = this.getOwnerFrame().arguments["FORM_PARAM"];   
        // if(_P_PARAMS){ 
        //     this.Div02.div_accno._setValue( _P_PARAMS.tacno+_P_PARAMS.bnkbSeq , _P_PARAMS.accSeq);
        //     this.Div02.cal_baseDate00._setValue(_P_PARAMS.baseDate); //기준일자셋팅 
        // }		 
      },		
  
      /***********************************************************************************
          조회
      **********************************************************************************/
      fn_searchList ()
      {   
        if ( TSCommUtil.gfn_length( this.getValue()) != 13 ){
          // this.gfn_getMessage("alert", "계좌번호를 확인하시오.");
          TSCommUtil.gfn_validate(this, '계좌번호를 확인하시오.')
          this.$refs.Edt_acno.focus()
          return;
        }
        if( TSCommUtil.gfn_isNull( this.cal_baseDate00) )
        {
          // this.gfn_getMessage("alert", "결산일자를 입력하시오.");
          TSCommUtil.gfn_validate(this, '결산일자를 입력하시오.')
          this.$refs.cal_baseDate00.focus()
          return;
        }

        this.ds_detail = Object.assign({}, defaultDsDetail)
        this.ds_m_search = Object.assign({}, defaultMainDsSearch)
        
        // 운영시 사용
        this.ds_m_search.baseDate = this.baseDate00
        this.ds_m_search.tacno = this._getTacnoValue()
        this.ds_m_search.bnkbSeq = this._getBnkbSeqValue() 
        this.ds_m_search.accSeq = this._getAccSeqValue() 
        this.ds_m_search.prrtCls = this.prrtCls	 // to-be 변환 완료

        // // 테스트용 jjh_test TODO: 테스트용임 삭제 요망
        // this.ds_m_search.baseDate = '20220531'
        // this.ds_m_search.tacno = '1000993'
        // this.ds_m_search.bnkbSeq = '001'
        // this.ds_m_search.accSeq = '002'
        // this.ds_m_search.prrtCls = ''	 // to-be 변환 완료

        // this.ds_m_search.baseDate = '20230510'
        // this.ds_m_search.tacno = '1101606'
        // this.ds_m_search.bnkbSeq = '000'
        // this.ds_m_search.accSeq = '000'
        // this.ds_m_search.prrtCls = ''	 // to-be 변환 완료

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600104_S'
        this.eaiCommObj.params = Object.assign({}, this.ds_m_search)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_m_callBack, this.fn_error)
        
        // var sSvcID        = "selectTFAC23300List";
        // var sURL          = "svc::selectTFAC23300List.do";
        // var sInDatasets   = "tfac23300=ds_m_search";
        // var sOutDatasets  = "ds_detail=tfac23300List";
        // var sArgument     = "";
        // var sCallbackFunc = "fn_m_callBack";
        
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
      },

      /************************************************************************************************
       * 조회 정상응답 후 처리 
       ************************************************************************************************/
      fn_m_callBack ( res )
      {
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          return false
        }

        if ( resData.tfac23300 != null && resData.tfac23300.length > 0 ) {
          this.ds_detail = Object.assign({}, resData.tfac23300[0])
        }
        else {
          // this.fn_AlertPopup(0,{content : '결과가 존재하지 않습니다.'}) 
          return false
        }

        console.log('fn_m_callBack 완료')

      // 	if ( svcID == "selectTFAC23400List") {
      // 	
      // // 		if ( this.ds_list.rowcount > 0 ) {
      // // 			this.Div02.cbo_tmtlReqSeq.set_index( 0) ;
      // // 		}
      // 		
      // 		if(_P_PARAMS){
      // 			 
      // 			this.Div02.cbo_tmtlReqSeq.set_value(_P_PARAMS.tmtlReqSeq );
      // 			this.fn_searchList();
      // 			_P_PARAMS = null;
      // 		}
      // 	
      // 	} 
      },

      /**********************************************************************************
       * 초기화  : WFtitleCRUDbtn 의 callback : 폼로드시 호출, 초기화버튼 클릭시 호출
       **********************************************************************************/
      fn_init ()
      {
        //데이터셋 row 초기화후 row추가 : row 없으면 입력 불가능.
        // this.ds_search.clearData();	
        // this.ds_detail.clearData();
        Object.assign(this.ds_detail, defaultDsDetail)
        Object.assign(this.ds_m_search, defaultMainDsSearch)
        this.list_Cbo_accSeq = []
        this.baseDate00 = this.lv_basInfo.busyDate // TSCommUtil.fn_strToday() // this.Div02.cal_baseDate00.set_value( this.gfn_getBusyDate() );
        this._fn_dataInit();	 
        this.fn_rmAllErrCss() // error class 전체 삭제
      },

      /**********************************************************************************
       * 계좌번호 입력시
       **********************************************************************************/
      acctCallBackFunc ()
      {
        this.fn_searchList();

      },

      /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) : 0: 계좌조회 
       ******************************************************************************/
      fn_OpenPopup(type, flag) {
        switch (type) {
          case 0:
            this.$refs.popup206.fn_Open()
            break          
        }
      },

      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       ******************************************************************************/
      fn_Popup_CallBack(pData) {
        console.log(pData)
        let t_popupObj = {}

        this.Edt_acno = pData.tacno.toString() + pData.bnkbSeq.toString()
        this.edt_accSeq = pData.accSeq  // 여기에 값을 찾아서 넣어야함
        this.fn_transaction()
      },

      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
      ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        switch (type) {
          case 0:
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
            }
            this.$refs.alertPopup.fn_Open()     
          break
        }
      },

      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 계좌번호 입력 시 필수값 검증 후 조회, 
       ******************************************************************************/
      Edt_acno_onkeyup(event) {
        console.log('fn_SearchValid.....')
        // var acnoValue = this.Edt_acno.value;
        let acnoValue = this.Edt_acno

        // 계좌번호 10자리 입력시 자동 조회 
        if ( TSCommUtil.gfn_length(acnoValue) == 10 ) {
          if( !TSCommUtil.gfn_isNum(event.key) && event.key !='Enter' && event.key != 'v' && !this.isMobile ) {
            return 
          }

          this.edt_accSeq = ''
          Object.assign(this.ds_msData, defaultDsMsData)
          if(this.popCls == "4"){
            this.fn_transactionB(acnoValue);
          }else{
            this.fn_transaction(acnoValue);
          }
        }
        // else {
        //   // 계좌번호 입력 후 엔터 시 계좌 조회 tr 호출 
        //   if ( event.keycode == 13 ) {
        //     if ( this.gfn_indexOf(acnoValue," ",0) < 0 && this.gfn_length(acnoValue) != 10 ) {
        //       //this.alert("계좌번호를 다시 입력하시오.");
        //       TSCommUtil.gfn_validate(this, '계좌번호를 다시 입력하시오.')
        //       this.$refs.Edt_acno.focus()
        //     }
        //     else {
        //       this.edt_accSeq = ''
        //       Object.assign(this.ds_msData, defaultDsMsData)
        //       this.fn_transaction(acnoValue);
        //     }
        //   }
        // }
      },

      fn_test(pParam) {
        console.log('fn_test')
        console.log(pParam)
      },

      /************************************************************************************************
      * 데이터 초기화 
      * 검색바
      ************************************************************************************************/
      _fn_dataInit ()
      { 
        this.Edt_acno = ''
        this.edt_cnm =''
        this.ds_list = []
        Object.assign(this.ds_search, defaultDsSearch)
        Object.assign(this.DatasetTemp, defaultDataSetTemp)
        Object.assign(this.ds_msData, defaultDsMsData)
        // this.Cbo_accSeq.init();
        // this.Div00.sts_passbook.set_text("");
        // this.Div00.sta_passbookState.set_text("");
        // this.Div00.sta_passbookState.style.set_color("black");		
      },

      /************************************************************************************************
       * 화면 조회 값 설정 
       * 검색바
       ************************************************************************************************/
      _setValue (strAcno, strAccSeq, strAcctStatC, strPopCls, strPassYn)
      {
        this.popCls = strPopCls;
        
        // 팝업구분에 따른 컨트롤 위치 변경 
        // 소스에서 사용하는 부분 못봄
        if ( strPopCls == "1" || strPopCls == "3" || strPopCls == "4") 
        {
          this.enabledList.Cbo_accSeq = false
          this.visibleList.Cbo_accSeq = false
          // this.btn_popupTPUP95350.set_left(91);
          // this.edt_cnm.set_left(115); 
          // this.edt_cnm.set_width(200); 
          // this.Div00.set_left(315);
        }

        // 소스에서 사용하는 부분 못봄
        if ( strPopCls == "0" ||  strPopCls == "2" ) 
        {
          this.enabledList.Cbo_accSeq = true
          this.visibleList.Cbo_accSeq = true
          // this.btn_popupTPUP95350.set_left(283);
          // this.edt_cnm.set_left(307); 
          // this.edt_cnm.set_width(135); 
          // this.Div00.set_left(442);
        }
        
        if ( !TSCommUtil.gfn_isNull(strPassYn)) {
          this.passYn = strPassYn; // 비밀번호 사용여부 
        }
        
        // 계좌 상태 코드 지정 
        // 소스에서 사용하는 부분 못봄
        var varAcctStatC = new String(this.gfn_nvl ( strAcctStatC , "" ) )
        this.Edt_accStatC_inData = varAcctStatC  // 값이 없는 경우 계좌 전체 조회 
        this.edt_accSeq = strAccSeq // 계좌일련번호 
        this.Edt_acno = strAcno
        var varAcno = new String(strAcno);

        // 계좌번호를 입력받은 경우 바로 조회한다. 
        if ( this.gfn_length(this.Edt_acno) == 10 ) { 
          if(strPopCls == "4"){
          
          this.fn_transactionB(this.Edt_acno)

          }else{
          this.fn_transaction(this.Edt_acno)
          }
        }
        
      },

      /************************************************************************************************
       * 계좌번호 값 리턴 
       ************************************************************************************************/
      getValue ()
      {		
        if ( this.ds_list.length > 0 ) {
          var oParamData = this.Edt_acno + this.Cbo_accSeq
          return oParamData;
        } else{
          return "";
        }	
      },

      /************************************************************************************************
       * 종합계좌번호 값 리턴 
       ************************************************************************************************/
      _getTacnoValue ()
      {	
        // 데이터가 있는경우 
        if ( this.ds_list.length > 0 ) {
          
          var oParamData = this.Edt_acno
          
          return oParamData.toString().substr(0,7);	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 통장일련번호 값 리턴 
       ************************************************************************************************/
      _getBnkbSeqValue ()
      {	
        // 데이터가 있는경우 
        if ( this.ds_list.length > 0 ) {
          var oParamData = this.Edt_acno
          return oParamData.toString().substr(7,3);	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 계좌일련번호 값 리턴 
       ************************************************************************************************/
      _getAccSeqValue ()
      {	
        // 데이터가 있는경우 
        if ( this.ds_list.length > 0 ) {
          var oParamData = this.Cbo_accSeq
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 계좌 정보 조회 TR 전송 
       * 검색바
       ************************************************************************************************/
      fn_transaction ()
      {
        // 계좌번호 조회 
        this.ds_list = [] // 데이터 셋 초기화
        Object.assign(this.ds_search, defaultDsSearch) // 데이터 셋 초기화

        this.ds_search.tacno =  this.Edt_acno.toString().substr(0,7)
        this.ds_search.bnkbSeq =  this.Edt_acno.toString().substr(7,3)
        this.ds_search.accStatC =  this.Edt_accStatC_inData
        this.ds_search.brigAccYn =  this.edt_brigAccYn01
        
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600245_S'
        this.eaiCommObj.params = Object.assign({}, this.ds_search)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack, this.fn_error)
        
        
        // var sSvcID        = "selectTPUP95090List";
        // var sURL          = "svc::selectTPUP95090List.do";
        // var sInDatasets   = "tpup95090=ds_search";
        // var sOutDatasets  = "ds_list=tpup95090List";
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBack";
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
      },


      /************************************************************************************************
      * 통장 정보 조회 TR 전송 
      * 검색바
      ************************************************************************************************/
      fn_transactionB ()
      {
        // 통장번호 조회 
        Object.assign(this.ds_list, defaultDsList) // 데이터 셋 초기화
        Object.assign(this.ds_search, defaultDsSearch) // 데이터 셋 초기화
        
        this.ds_search.tacno =  this.Edt_acno.value.toString().substr(0,7)
        this.ds_search.bnkbSeq =  this.Edt_acno.value.toString().substr(7,3)
        
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600246_S'
        this.eaiCommObj.params = Object.assign({}, this.ds_search)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack, this.fn_error)

        // var sSvcID        = "selectTPUP95090ListB";
        // var sURL          = "svc::selectTPUP95090ListB.do";
        // var sInDatasets   = "tpup95090=ds_search";
        // var sOutDatasets  = "ds_list=tpup95090List";
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBack";
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
      },


      /************************************************************************************************
      * 계좌조회 정상응답 후 처리 
      * 검색바
      ************************************************************************************************/
      fn_callBack ( res )
      {
        // 응답코드가 정상이 아닌경우 
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          return false
        }

        if ( resData.tpup95090 != null && resData.tpup95090.length > 0 ) {
          // Object.assign ( this.ds_list, resData.tpup95090) 
          this.ds_list = resData.tpup95090
          let rtn = []
          this.ds_list.forEach(function(item,idx){
            rtn.push({value: item.accSeq , text: item.gdNm })
          })
          this.list_Cbo_accSeq = rtn
        }
        else {
          this.fn_AlertPopup(0,{content : '결과가 존재하지 않습니다.'}) 
          return false
        }
        // this.Cbo_accSeq.setFocus();

        // 초기값 설정 
        this.edt_curC = this.ds_list[0].curC
        this.cal_contDate = this.ds_list[0].contDate
        this.cal_mtrtDate = this.ds_list[0].mtrtDate
        this.edt_brigAccYn = this.ds_list[0].brigAccYn
        this.edt_trstTypC = this.ds_list[0].trstTypC
        this.edt_gdC = this.ds_list[0].gdC
        this.edt_trstAmt = this.ds_list[0].trstAmt
        this.edt_gdTypDtlC = this.ds_list[0].gdTypDtlC
        this.edt_mngBrcd = this.ds_list[0].mngBrcd
        this.edt_csId = this.ds_list[0].csId
        this.edt_cnm = this.ds_list[0].cnm
        this.edt_fundC = this.ds_list[0].fundC
        this.edt_rcno = this.ds_list[0].rcno
        this.edt_rcnoKindTc = this.ds_list[0].rcnoKindTc
        this.edt_soptTc = this.ds_list[0].soptTc
        this.edt_gdNm = this.ds_list[0].gdNm
        
        if ( this.popCls == "2" ||  this.popCls == "3" ) {
          if ( !TSCommUtil.gfn_isNull(this.ds_list[0].admkNm)) {
            this.edt_cnm = this.ds_list[0].cnm + "[" + this.ds_list[0].admkNm + "]" 
          }
          this.edt_bnkbAccYn = "Y"  
        }else if(this.popCls == "4"){
          if(svcID == "selectTPUP95090ListB"){
            if ( !TSCommUtil.gfn_isNull(this.ds_list[0].bnkb)) { //등록된 계좌가 있는지 
              this.edt_bnkbAccYn = "Y" //등록된 계좌있음
              this.fn_transaction(this.Edt_acno);
            }else{
              this.edt_bnkbAccYn = "N" //등록된 계좌없음
            }
          }else{
            this.edt_bnkbAccYn = "Y" //등록된 계좌있음
          }
          if ( !TSCommUtil.gfn_isNull(this.ds_list[0].admkNm)) {
            this.edt_cnm = this.ds_list[0].cnm + "[" + this.ds_list[0].admkNm + "]" 
          }
        }
        
        if ( !TSCommUtil.gfn_isNull(this.edt_accSeq)) { // 계좌일련번호가 존재하면 바로 세팅 
          this.Cbo_accSeq = this.edt_accSeq
          //  this.Cbo_accSeq_onitemchanged(); watch에서 처리
          return ;
        }
        else {
          this.Cbo_accSeq = this.ds_list[0].accSeq // this.Cbo_accSeq.set_index(0);
        }
          
        // 계좌번호가 있는 경우에만 호출화면으로 메소드를 전달한다. 
        if ( this.ds_list.length > 0 ) {
          try{
            if ( this.passYn == "Y" ) {
              console.lg('acctCallBackFuncTacno 해당 함수')
              // this.lookupFunc("acctCallBackFuncTacno").call(); // 존재하지 않음....
            }
            else {
              this.fn_searchList() // this.lookupFunc("acctCallBackFunc").call();
            }
          } catch(e){
          }	
        }
      },

      /************************************************************************************************
      * 계좌정보조회 팝업 호출 
      ************************************************************************************************/
      btn_popupTPUP95350_onclick ()
      {
        // 초기화
        this.DatasetTemp.clearData();
        
        var oArg           = {cnm:""};  //팝업화면으로 보낼 파라미터.
        var sOption        = "autosize=true,title=true";           //팝업열때 옵션
        var sPopupCallBack = "fn_popupAfter";                      //팝업화면에서 보낸 파라미터 받는곳
        
        this.gfn_openPopup("TPUP95350","popup::TPUP95350.xfdl",oArg,sOption,sPopupCallBack);	
      },

      /**
       * 팝업화면에서 보낸 파라미터 받는곳(id, param)
       * param은 문자열만 가능(object으로 받을 수 없음. 즉 Dataset은 안됨)
       * 검색바
       */
      fn_popupAfter (strId, strVal)
      {
        
        if(!TSCommUtil.gfn_isNull(this.pv_popupResult.tacno)){
          this.DatasetTemp.clearData();
          this.DatasetTemp.loadXML(strVal);
          this.Edt_acno.set_value(this.pv_popupResult.tacno + this.pv_popupResult.bnkbSeq);
          this.edt_accSeq.set_value(this.pv_popupResult.accSeq);
          this.fn_transaction(); // 조회 처리 
        }	
      },

      /************************************************************************************************
      * 콤보박스 변경 이벤트 
      * 검색바
      ************************************************************************************************/
      Cbo_accSeq_onitemchanged ()
      {
        for ( let idx in this.ds_list ) {
          if ( this.Cbo_accSeq == this.ds_list[idx].accSeq ) {
            console.log(this.ds_list[idx] )

            // 콤보값이 변경될때마다 데이터 변경 
            this.edt_curC = this.ds_list[idx].curC
            this.cal_contDate = this.ds_list[idx].contDate
            this.cal_mtrtDate = this.ds_list[idx].mtrtDate
            this.edt_brigAccYn = this.ds_list[idx].brigAccYn
            this.edt_trstTypC = this.ds_list[idx].trstTypC
            this.edt_gdC = this.ds_list[idx].gdC
            this.edt_trstAmt = this.ds_list[idx].trstAmt
            this.edt_gdTypDtlC = this.ds_list[idx].gdTypDtlC
            this.edt_mngBrcd = this.ds_list[idx].mngBrcd
            this.edt_csId = this.ds_list[idx].csId
            this.edt_fundC = this.ds_list[idx].fundC
            this.edt_rcno = this.ds_list[idx].rcno
            this.edt_rcnoKindTc = this.ds_list[idx].rcnoKindTc
            this.edt_soptTc = this.ds_list[idx].soptTc
            this.edt_gdNm = this.ds_list[idx].gdNm
            break
          }
        }

        // 계좌번호가 있는 경우에만 호출화면으로 메소드를 전달한다. 	
        if ( this.ds_list.length > 0 ) {
          try{
            this.fn_searchList() // this.lookupFunc("acctCallBackFunc").call();
          } catch(e){
          }	
        }
      },


      /************************************************************************************************
      * 데이터 변경시 계좌일련번호 콤보와 고객명 초기화
      * 검색바 
      ************************************************************************************************/
      Edt_acno_ontextchange ()
      {
        if ( this.ds_list.rowcount > 0 ) {
          this.ds_list.clearData();	
        }
        
        if(this.ds_msData.rowcount > 0) {
          this.ds_msData.clearData();	
        }
      },

      /*=========================================================================
      /* 메소드 명 : fn_error 
      /* 내용설명  : invoke 함수 공통 콜백 에러 처리 함수 
      /* Return 값 :                                                             
      =========================================================================*/
      fn_error (res) {
        console.log("에러 콜백")
        let msg = ''
        if ( res.msgDesc ) {
          msg = res.msgDesc
        } else {
          msg = res
        }
        this.fn_AlertPopup(0,{ content : msg} )
        // this.ds_detail = this.ds_detail,res.data.body
      } ,

      /***************************************************************************************
        벨리데이션 error css, 메시지 처리 함수 (기본형)
        param : 배열 오브젝트
        return : boolean / true : 정상 , flase : 체킹됨

        내부 함수 localValid 리턴값을 리턴한다
        단건라인 호출시 : this.fn_validate( [{ mValue:this.g_cmb_modifyGb , title: '정정구분', ref:'cmb_modifyGb' }] )
      *****************************************************************************************/
      fn_validate ( pParam ) {
        
        // console.log(window.vue.getStore('userInfo').getters.getUserInfo) // getStore sesseion 정보
        
        let pValidArry = [];
        if ( ! pParam.length > 0 ) { // 값이 없을 경우 기본 디폴트값 설정 또는 무시
          console.log('테스트 벨리데이션..bundleRenderer.renderToString')
          pValidArry = [
            { mValue:this.ds_detail.rcno , title: '실명번호', ref:'edt_rcno23232' },
            { mValue:this.banana , title: '바나나 input', ref:'banana' },
          ]
        } else {
          pValidArry = pParam
        }
        
        // 실행 및 리턴
        const lv_vm = this
        let rtnBool = true
        for ( let idx in pValidArry ) {
          // console.log(this.validCheck[arguments[i]])
          let modelObj = pValidArry[idx]
          if(TSCommUtil.gfn_isNull(modelObj.mValue)){
            rtnBool = false
            
            try {
              const lv_el = lv_vm.$refs[modelObj.ref].$el
              lv_el.focus()
              // const thidDom = lv_vm.$refs[modelObj.ref].$el
              if ( ! lv_el.classList.contains('error') ) {  // class에 erorr가 없을시만 추가
                lv_el.classList.add('error')
              }
              TSCommUtil.gfn_validate(lv_vm, modelObj.title+"은(는) 필수입력 항목입니다.")
            } catch (error) {
              console.log(modelObj.ref + '실패')
              continue
            }
          }
        }
        return rtnBool
      },

      /******************************************************************************
       * Function명 : removeError
       * 설명       : css error 삭제
       ******************************************************************************/
      removeError (target) {
        
        // 현재 뎁스만 param 으로 넘겨받아 처리
        // target.classList.remove('error')

        // 부모 뎁스 까지 확인
        if ( target.parentElement.parentElement.classList.contains('error') ) {
          target.parentElement.parentElement.classList.remove('error')
        }
        else if ( target.parentElement.classList.contains('error') ) {
          target.parentElement.classList.remove('error')
        }
        else {
          target.classList.remove('error')
        }
      },

      /******************************************************************************
      * Function명 : fn_rmAllErrCss
      * 설명       : 해당 컴포넌트 error css 삭제
      ******************************************************************************/
      fn_rmAllErrCss () {
        for ( let idx in this.$refs ) {
          if ( this.$refs[idx] != undefined ) {
            this.$refs[idx].$el.classList.remove("error")
          }
        }
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      Cbo_accSeq : {
        handler: function(val, oldVal) { 
          if ( val == oldVal ) 
            return false
          
          this.Cbo_accSeq_onitemchanged()
        },
      },
      prrtCls : { // 수익률 구분
        handler: function(val, oldVal) { 
          if ( val == oldVal ) 
            return false

          if ( val == '1'  ) {
            this.prrtClsTtTxt = '세전수익률'
          }
          else {
            this.prrtClsTtTxt = '연환산수익률'
          }
          this.fn_searchList();
        },
      },
      
    }
  }
</script>

<style lang="scss">
</style>

<style scoped>
</style>
